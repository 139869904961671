import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Check, Loader2 } from 'lucide-react';

interface FormData {
  nama: string;
  sektor: string;
  desa: string;
}

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    nama: '',
    sektor: '',
    desa: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const kecamatanOptions = ['Arjasari', 'Banjaran', 'Cangkuang', 'Cimaung', 'Pangalengan'];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbwd5HNeJH4IhPsKEIZTeBH2plwwur4euNQCxE44w2g9myNpJ9pXP3Oen7VKpUyq-DXy/exec', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify({
          data: [
            new Date().toLocaleString('id-ID'),
            formData.nama,
            formData.sektor,
            formData.desa
          ],
          sheet: 'Data Pemetaan'
        })
      });

      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setFormData({
          nama: '',
          sektor: '',
          desa: ''
        });
      }, 2000);
    } catch (error) {
      console.error('Error:', error);
      alert('Terjadi kesalahan saat menyimpan data. Silakan coba lagi.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="max-w-2xl mx-auto p-8 bg-gradient-to-br from-emerald-50/80 via-green-50/80 to-teal-50/80 rounded-2xl shadow-lg"
    >
      <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
        Form Pemetaan Wilayah
      </h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4">
          <motion.div
            whileHover={{ scale: 1.01 }}
            className="group"
          >
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Nama Lengkap
            </label>
            <input
              type="text"
              name="nama"
              value={formData.nama}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 transition-all duration-300 bg-white bg-opacity-80 backdrop-blur-sm group-hover:border-emerald-400"
              placeholder="Masukkan nama lengkap"
            />
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.01 }}
            className="group"
          >
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Kecamatan
            </label>
            <select
              name="sektor"
              value={formData.sektor}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 transition-all duration-300 bg-white bg-opacity-80 backdrop-blur-sm group-hover:border-emerald-400"
            >
              <option value="">Pilih Kecamatan</option>
              {kecamatanOptions.map((kecamatan) => (
                <option key={kecamatan} value={kecamatan}>{kecamatan}</option>
              ))}
            </select>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.01 }}
            className="group"
          >
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Desa
            </label>
            <input
              type="text"
              name="desa"
              value={formData.desa}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 transition-all duration-300 bg-white bg-opacity-80 backdrop-blur-sm group-hover:border-emerald-400"
              placeholder="Masukkan nama desa"
            />
          </motion.div>
        </div>

        <motion.button
          type="submit"
          disabled={isSubmitting}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="w-full inline-flex justify-center items-center px-6 py-3 text-white bg-gradient-to-r from-emerald-500 to-teal-600 rounded-lg hover:from-emerald-600 hover:to-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300 shadow-md hover:shadow-lg"
        >
          {isSubmitting ? (
            <>
              <Loader2 className="w-5 h-5 mr-2 animate-spin" />
              Menyimpan...
            </>
          ) : (
            'Simpan Data'
          )}
        </motion.button>
      </form>

      {showSuccess && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="fixed inset-0 flex items-center justify-center z-50"
        >
          <div className="absolute inset-0 bg-black bg-opacity-40 backdrop-blur-sm"></div>
          <motion.div 
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            className="relative bg-white rounded-xl p-8 shadow-2xl"
          >
            <div className="flex flex-col items-center">
              <motion.div 
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                className="w-16 h-16 rounded-full bg-gradient-to-br from-emerald-100 to-teal-100 flex items-center justify-center mb-4"
              >
                <Check className="w-8 h-8 text-emerald-600" />
              </motion.div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">Berhasil!</h3>
              <p className="text-sm text-gray-600">Data telah berhasil disimpan</p>
            </div>
          </motion.div>
        </motion.div>
      )}
    </motion.div>
  );
};

export default ContactForm;